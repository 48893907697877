import React, {lazy, Suspense} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import FullPageLoader from "./components/Generic/Loaders/FullPageLoader";

const GetFundedPage = lazy(() => import("./pages/getfunded"));
const CandidateIngestionPage = lazy(() => import("./pages/candidate_ingestion"));
const BenchIngestionPage = lazy(() => import("./pages/bench_ingestion"));

const PageNotFound = lazy(() => import("./components/Generic/PageNotFound/PageNotFound"));

function App() {
    return <BrowserRouter>
        <Suspense fallback={<FullPageLoader/>}>
            <Routes>
                <Route path="/apply" element={<CandidateIngestionPage/>}>
                    <Route path=":token" element={<CandidateIngestionPage/>}/>
                </Route>
                <Route path="/bench" element={<BenchIngestionPage/>}>
                    <Route path=":token" element={<BenchIngestionPage/>}/>
                </Route>
                <Route path="/getfunded" element={<GetFundedPage/>}>
                    <Route path=":token" element={<GetFundedPage/>}/>
                </Route>
                
                {(window.location.hostname === 'apply.beepartners.vc'
                    || window.location.hostname === 'apply-staging.beepartners.vc'
                    || window.location.hostname === 'local-apply.beepartners.vc')
                    && <Route path="/" element={<CandidateIngestionPage/>}>
                    <Route path=":token" element={<CandidateIngestionPage/>}/>
                </Route>}
                {(window.location.hostname === 'bench.beepartners.vc'
                    || window.location.hostname === 'bench-staging.beepartners.vc'
                    || window.location.hostname === 'local-bench.beepartners.vc')
                    && <Route path="/" element={<BenchIngestionPage/>}>
                    <Route path=":token" element={<BenchIngestionPage/>}/>
                </Route>}
                {(window.location.hostname === 'getfunded.beepartners.vc' 
                    || window.location.hostname === 'getfunded-staging.beepartners.vc'
                    || window.location.hostname === 'bee-partners-portal-staging.herokuapp.com'
                    || window.location.hostname === 'local-getfunded.beepartners.vc'
                    || window.location.hostname === 'localhost') && <Route path="/" element={<GetFundedPage/>}>
                    <Route path=":token" element={<GetFundedPage/>}/>
                </Route>}
                <Route path="*" element={<PageNotFound/>}/>
            </Routes>
        </Suspense>
    </BrowserRouter>;
}

export default App;
